import { request } from '../../config';
import {
  AUTH_ERROR,
  CLEAR_ERRORS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_PERMISSIONS,
  USER_LOADED,
  USER_LOADING,
  USER_SWITCHED,
} from './type';

import UserService from '../../services/UserServices';
import { returnErrors } from './errorAction';

// check token and load user
export const loadUser = () => (dispatch) => {
  // User Loading
  dispatch({ type: USER_LOADING });

  UserService.getProfile()
    .then((res) => {
      dispatch({ type: USER_LOADED, payload: res.data });
    })
    .catch((err) => {
      // get error message
      dispatch(returnErrors(err.response.data, err.response.status));
      //remove token
      dispatch({ type: AUTH_ERROR });
    });
};

// Login User
export const login = (email, password) => (dispatch) => {
  dispatch({ type: USER_LOADING });

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email, password });

  request
    .post(`/login`, body, config)
    .then((res) => {
      if (res) {
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        dispatch({ type: CLEAR_ERRORS });
      } else {
        throw new Error('Gagal Login');
      }
    })
    .catch((err) => {
      // console.log('LOGIN ERR', err)
      dispatch(returnErrors(err.message, 400, 'LOGIN_FAIL'));
      dispatch({ type: LOGIN_FAIL });
    });
};

// Login As User
export const loginAs = (users_id) => (dispatch) => {
  dispatch({ type: USER_LOADING });

  UserService.loginAs(users_id)
    .then((res) => {
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: USER_SWITCHED, payload: true });
      setTimeout(() => {
        dispatch({ type: USER_SWITCHED, payload: false });
      }, 3000);
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    });
};

export const refreshPermissions = () => (dispatch) => {
  UserService.refreshPermissions()
    .then((res) => {
      dispatch({ type: SET_PERMISSIONS, payload: res.data });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: AUTH_ERROR });
    });
};

// Logout User
export const logout = () => (dispatch) => {
  UserService.logout()
    .then(() => {
      dispatch({ type: LOGOUT_SUCCESS });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: AUTH_ERROR });
    });
};
