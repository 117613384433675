import { Avatar, Layout, Space } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import Logo from '../assets/img/logo_putih.png';
// import Label from '../assets/img/label_coversuper.png'
import SideNavbar from '../components/SideNavbar';
import { FLOW_VERSION } from '../constants';
import { logout } from '../store/Auth/action';
import { resetCustomer } from '../store/FormOrder/Customer/action';
import { resetProduct } from '../store/FormOrder/Products/action';
import { resetDataSummary } from '../store/FormOrder/Summary/action';
import useRefreshPermissions from './RefreshPermissions';

const { Header, Sider, Content } = Layout;

const DashboardLayout = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // Refresh permissions
  useRefreshPermissions(user);

  const [collapsed, setCollapsed] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetProduct());
    dispatch(resetCustomer());
    dispatch(resetDataSummary());
  };

  return (
    <Layout className="bg-slate-100" style={{ minHeight: '100vh' }} hasSider>
      <Sider
        className="pt-3 bg-gradient-to-b text-gray-300 lg:overflow-y-auto from-[#141E30] to-[#141E30]"
        breakpoint="xl"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log('broken', broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
          setCollapsed(collapsed);
        }}
        width={230}
        style={{
          height: '100vh',
          position: 'fixed',
          zIndex: 99,
        }}
      >
        <div className="flex items-center justify-center">
          <img className="mb-8 ml-5 mt-1" src={Logo} alt="" width={130} />
          <h1 className="text-white font-semibold text-center px-1">v{FLOW_VERSION}</h1>
        </div>

        <SideNavbar />
      </Sider>

      <Layout
        className="bg-slate-100"
        style={{
          marginLeft: collapsed ? 0 : 220,
        }}
      >
        <Header className="bg-white relative w-full h-16 px-5 shadow-md flex justify-end items-center">
          <Space direction="horizontal">
            <div className="flex items-center gap-2 border-x px-2">
              <div className="bg-cyan-600 rounded-full flex items-center justify-center">
                <Avatar
                  onClick={() => console.log('clicked')}
                  size={30}
                  src={`https://api.dicebear.com/7.x/initials/svg?seed=${user?.name}&backgroundColor=3949ab,00897b&backgroundType=gradientLinear`}
                />
              </div>

              <div>
                <h2 className="text-gray-400 font-medium text-xs">
                  <span className="text-gray-400">{user.name}</span>
                </h2>
                <h2 className="text-red-400 font-medium text-xs">
                  <button className="text-blue-400 bg-white" onClick={handleLogout}>
                    Logout
                  </button>
                </h2>
              </div>
            </div>
          </Space>
        </Header>

        <Content
          style={{
            margin: '24px 2rem',
            padding: 14,
            minHeight: 280,
            overflow: 'initial',
            textAlign: 'left',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
