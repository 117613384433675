import { request } from '../config';

// versi function
function UserService() {
  // Get Data for Datatable
  const getDatatable = async (params) => {
    const response = await request.get(`/users/pagination`, { params });
    return response.data;
  };

  const activeUsers = async () => {
    const response = await request.get(`/users/active`);
    return response.data;
  };

  const changeStatus = async (users_id) => {
    const response = await request.put(`/users/change-status/${users_id}`);
    return response.data;
  };

  const changeProfile = async (data) => {
    const response = await request.put(`/users/profile`, data);
    return response.data;
  };

  const getProfile = async () => {
    const response = await request.get(`/users/profile`);
    return response.data;
  };

  // Show List
  const listRole = async () => {
    const response = await request.get(`/roles`);
    return response.data;
  };

  const listUnit = async () => {
    const response = await request.get(`/units`);
    return response.data;
  };

  const listUserByUnit = async (unit_id) => {
    const response = await request.get(`/units/${unit_id}/pic`);
    return response.data;
  };

  // Show detail
  const findById = async (users_id) => {
    const response = await request.get(`/users/${users_id}`);
    return response;
  };

  const save = async (data) => {
    const response = await request.post(`/users`, data);
    return response.data;
  };

  // update Data
  const update = async (users_id, data) => {
    const response = await request.put(`/users/${users_id}`, data);
    return response;
  };

  const remove = async (users_id) => {
    const response = await request.delete(`/users/${users_id}`);
    return response.data;
  };

  const loginAs = async (user_id) => {
    const response = await request.put(`/users/login-as/${user_id}`);
    return response;
  };

  const logout = async () => {
    const response = await request.post(`/logout`);
    return response;
  };

  const refreshPermissions = async () => {
    const response = await request.get(`/users/refresh-permissions`);
    return response;
  };

  const getCsLevel = async () => {
    const response = await request.get(`/users/levels`);
    return response.data;
  };

  return {
    getDatatable,
    activeUsers,
    changeStatus,
    getProfile,
    changeProfile,
    listRole,
    listUnit,
    listUserByUnit,
    findById,
    save,
    update,
    remove,
    getCsLevel,
    loginAs,
    logout,
    refreshPermissions,
  };
}

export default UserService();
