// in index.js
import packageJson from '../../package.json';
const isBeta = process.env.REACT_APP_BETA === 'true';

const isLocal = process.env.REACT_APP_LOCAL === 'true';

export const SELFSERVICE_URL = isLocal
  ? 'http://localhost:3030'
  : isBeta
  ? 'https://web.coversuper.com'
  : 'https://coversuper.com';
/**
 * VERSI FLOW
 */
// Ganti Version di package.json
export const FLOW_VERSION = packageJson.version + (isBeta ? '-beta' : '');

/**
 * UNIT / DIVISI
 */
export const UNIT_GREAT = 1;
export const UNIT_EXCELLENT = 2;
export const UNIT_FORCE = 3;
export const UNIT_WARRIORS = 4;
export const UNIT_KOPASSUS_2 = 5;
export const UNIT_KOPASSUS_3 = 6;
export const UNIT_CHAMP = 7;
export const UNIT_SEAT_COVER = 8;
export const UNIT_EL_MATADOR = 9;
export const UNIT_GLORY = 10;
export const UNIT_AVENGERS = 11;

export const UNIT_SETTINGS = {
  excellent: {
    backgroundColor: '#d23d3d',
    lineColor: '#d23d3d',
    textColor: '#fff',
  },
  great: {
    backgroundColor: '#fc8380',
    lineColor: '#fc8380',
    textColor: '#000',
  },
  elmatador: {
    backgroundColor: '#20409a',
    lineColor: '#20409a',
    textColor: '#efad22',
  },
  seatcover: {
    backgroundColor: '#120202',
    lineColor: '#120202',
    textColor: '#fff',
  },
  force: {
    backgroundColor: '#ef64f2',
    lineColor: '#ef64f2',
    textColor: '#000',
  },
  glory: {
    backgroundColor: '#F7418F',
    lineColor: '#F7418F',
    textColor: '#fff',
  },
  warriors: {
    backgroundColor: '#FF7F00',
    lineColor: '#FF7F00',
    textColor: '#000',
  },
  kopassus_2: {
    backgroundColor: '#5fff5f',
    lineColor: '#5fff5f',
    textColor: '#000',
  },
  kopassus_3: {
    backgroundColor: '#0ac10a',
    lineColor: '#0ac10a',
    textColor: '#000',
  },
  champ: {
    backgroundColor: '#02ccfe',
    lineColor: '#02ccfe',
    textColor: '#000',
  },
  avengers: {
    backgroundColor: '#964b00',
    lineColor: '#964b00',
    textColor: '#fff',
  },
};

/**
 * ROLE
 */
export const ROLE_ADMIN = 1;
export const ROLE_DEALMAKER = 2;
export const ROLE_SHIPPER = 3;
export const ROLE_RESEPER = 4;
export const ROLE_POTONG = 5;
export const ROLE_JAHIT = 6;
export const ROLE_QC = 7;
export const ROLE_PACKING = 8;
export const ROLE_RESI = 9;
export const ROLE_ADMIN_PRODUCTION = 10;
export const ROLE_FINANCE = 11;
export const ROLE_MARKETING = 12;
export const ROLE_CS_SUPERVISOR = 13;
// export const ROLE_PENJAHIT_EKSTERNAL = 14
// export const ROLE_ADMIN_PRODUCTION_2 = 15
export const ROLE_CS_CARE = 16;
export const ROLE_OPERASIONAL = 17;
// export const ROLE_AFFILIATE = 18
export const ROLE_CS_EMIRATE = 19;
export const ROLE_POLA = 22;
export const ROLE_POLA_POTONG = 23;
export const ROLE_SUPPORT_PRODUKSI = 24;
export const ROLE_POLA_HUNTER = 25;
export const ROLE_HR = 26;
export const ROLE_PRODUCT_DEVELOPMENT = 27;
export const ROLE_CUSTOMER = 100;

/*
   |--------------------------------------------------------------------------
   | CUSTOMER TYPE
   |--------------------------------------------------------------------------
   */
export const CUSTOMER_RETAIL = 1;
export const CUSTOMER_RESELLER = 2;
export const CUSTOMER_AGEN = 3;

/*
   |--------------------------------------------------------------------------
   | PAYMENT STATUS
   |--------------------------------------------------------------------------
   */
export const PAYMENT_STATUS_UNPAID = 1;
export const PAYMENT_STATUS_DP = 2;
export const PAYMENT_STATUS_PAID = 3;
export const PAYMENT_STATUS_REFUND = 4;
export const PAYMENT_STATUS_CANCEL = 5;

export const PAYMENT_STATUS_COD_MP = 6;
export const PAYMENT_STATUS_MARKETPLACE = 7;
export const PAYMENT_STATUS_BILLING = 8;

/**
   |--------------------------------------------------------------------------
   | PRODUCTION STATUS
   |--------------------------------------------------------------------------
  */
export const STATUS_UNVERIFIED = 2;
export const STATUS_CETAK_LABEL = 30;
export const STATUS_ANTRI_PRODUKSI = 3;
export const STATUS_ANTRI_DESAIN = 4;
export const STATUS_ANTRI_DESAIN_KARPET_JOK = 5;
export const STATUS_DESAIN_SEDANG_DIBUAT = 6;
export const STATUS_ANTRI_POTONG = 7;
export const STATUS_ANTRI_JAHIT = 9;
export const STATUS_PROSES_SABLON = 11;
export const STATUS_ANTRI_QC = 12;
export const STATUS_QC_CHECK = 15;
export const STATUS_PROSES_PACKING = 18;
export const STATUS_SEDANG_DIKIRIM = 20;
export const STATUS_DITERIMA = 21;
export const STATUS_CANCEL = 22;
export const STATUS_PENDING = 27;

/**
   |--------------------------------------------------------------------------
   | PLATFORM
   |--------------------------------------------------------------------------
  */
export const PLATFORM_REGULAR = 'Regular';
export const PLATFORM_BUKALAPAK = 'Bukalapak';
export const PLATFORM_TOKOPEDIA = 'Tokopedia';
export const PLATFORM_SHOPEE = 'Shopee';
export const PLATFORM_LAZADA = 'Lazada';
export const PLATFORM_BLIBLI = 'Blibli';
export const PLATFORM_TIKTOK = 'TikTok';

/**
 * OBJECT TYPE
 */
export const OBJECT_TYPE_MOBIL = 1;
export const OBJECT_TYPE_MOTOR = 2;
export const OBJECT_TYPE_SEPEDA = 3;
export const OBJECT_TYPE_KARPET = 9;

/**
 * KATEGORI PRODUK
 */

export const CATEGORY_COVER_MOBIL = 1;
export const CATEGORY_COVER_MOTOR = 2;
export const CATEGORY_KARPET_MOBIL = 9;
export const CATEGORY_COVER_JOK = 10;

/**
  |--------------------------------------------------------------------------
  | CS LEVEL
  |--------------------------------------------------------------------------
  */
export const CS_LEVEL_TRAINING = 1;
export const CS_LEVEL_MEGA = 2;
export const CS_LEVEL_GIGA = 3;
export const CS_LEVEL_TERRA = 4;
export const CS_LEVEL_PETA = 5;

/**
 * SHIPPING STATUS
 */

export const SHIPPING_STATUS_SIAP_PICKUP = 1;
export const SHIPPING_STATUS_MENUNGGU_RESI = 2;
export const SHIPPING_STATUS_PENGIRIMAN_TERJADWAL = 3;
export const SHIPPING_STATUS_MENUNGGU_KURIR = 4;
export const SHIPPING_STATUS_SEDANG_DIKIRIM = 5;
export const SHIPPING_STATUS_DITOLAK = 6;
export const SHIPPING_STATUS_RETUR = 7;
export const SHIPPING_STATUS_SELESAI = 8;
